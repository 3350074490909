export class Node<K, V> {
  public readonly children = new Map<K, Node<K, V>>();
  constructor(
    public readonly key: K,
    public value: V,
  ) {}
}

export class Trie<K, V> {
  public readonly children = new Map<K, Node<K, V>>();
}

export function lookup<K, V>(trie: Trie<K, V>, keys: Iterable<K>): undefined | Node<K, V> {
  let node: undefined | Node<K, V> = undefined;
  for (const key of keys) {
    const child: undefined | Node<K, V> = (node ?? trie).children.get(key);
    if (!child) {
      return undefined;
    }

    node = child;
  }

  return node;
}

export function match<K, V>(trie: Trie<K, V>, keys: Array<K>): [Array<K>, undefined | Node<K, V>, Array<K>] {
  let node: undefined | Node<K, V> = undefined;
  for (const [index, key] of keys.entries()) {
    const child: undefined | Node<K, V> = (node ?? trie).children.get(key);
    if (!child) {
      return [keys.slice(0, index), node, keys.slice(index)];
    }

    node = child;
  }

  return [keys, node, []];
}

export function path<K, V>(trie: Trie<K, V>, keys: Iterable<K>): Array<Node<K, V>> {
  const path: Array<Node<K, V>> = [];

  let node: undefined | Node<K, V> = undefined;
  for (const key of keys) {
    const child: undefined | Node<K, V> = (node ?? trie).children.get(key);
    if (!child) {
      return path;
    }

    node = child;
    path.push(node);
  }

  return path;
}

export function insert<K, V>(trie: Trie<K, V>, keys: Iterable<K>, key: K, value: V): Node<K, V> {
  return insertWith(trie, keys, key, value, () => {});
}

export function insertWith<K, V>(trie: Trie<K, V>, keys: Iterable<K>, key: K, value: V, f: (node: Node<K, V>) => void): Node<K, V> {
  const parent = lookup(trie, keys) ?? trie;
  if (!parent) {
    throw new Error("insertWith: parent not present");
  }

  let node = parent.children.get(key);
  if (!node) {
    node = new Node(key, value);
    parent.children.set(key, node);
  }

  f(node);

  return node;
}
