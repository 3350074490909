import { insertWith, path, Trie } from "~/data/Trie";
import * as F from "fp-ts";
import * as React from "react";

/**
 * The underlying DOM element which is rendered by this component.
 */
const Root = React.Fragment;
interface Props extends React.ComponentPropsWithoutRef<typeof Root> {
  refs: F.nonEmptyArray.NonEmptyArray<React.MutableRefObject<null | HTMLDivElement>>;
  childElements: Array<React.ReactElement & {
    key: React.Key;
  }>;
}
interface Column {
  index: number;
  ref: React.MutableRefObject<null | HTMLDivElement>;
  children: React.ReactElement[];
}

/**
 * Ord instance for 'Column' which sorts by column height, ascending (smallest
 * column first).
 */
const ordColumn = F.ord.contramap<number, Column>(({
  ref
}) => ref.current?.getBoundingClientRect().height ?? 0)(F.number.Ord);
function Columns(props: Props) {
  const id = React.useId();
  const {
    refs,
    childElements,
    ...rest
  } = props;

  /*
   * The number of columns can be considered constant for the lifetime of the
   * component, because the parent remounts the component when that changes.
   *
   * The number is also always greater than zero.
   */
  const numColumns = refs.length;
  const [state, setState] = React.useState(() => {
    let nextChildIndex = 0;
    const columns = F.nonEmptyArray.makeBy((index): Column => ({
      index,
      ref: refs[index],
      children: []
    }))(numColumns);
    const keysToPlace = childElements.map(child => child.key);
    const trie = cache.get(id);
    if (trie) {
      for (const [index, node] of path(trie, keysToPlace).entries()) {
        const column = node.value[numColumns];
        if (typeof column !== "number") {
          break;
        }
        columns[column].children.push(childElements[index]);
        nextChildIndex = index + 1;
        if (nextChildIndex > 10) {
          break;
        }
      }
    }
    return {
      columns,
      nextChildIndex
    };
  });

  /*
   * In each tick, try to place one more item (from 'itemsToPlace') into one of
   * the columns.
   */
  React.useEffect(() => {
    setState(state => {
      const item = childElements[state.nextChildIndex];
      if (!item) {
        return state;
      }
      const column = F.nonEmptyArray.min<Column>(ordColumn)(state.columns);
      column.children = [...column.children, item];
      cache.append(id, [...childElements.slice(0, state.nextChildIndex).map(child => child.key)], numColumns, item.key, column.index);
      state.nextChildIndex++;
      return {
        columns: state.columns,
        nextChildIndex: state.nextChildIndex
      };
    });
  }, [setState, childElements, state.nextChildIndex]);
  return <Root {...rest}>
      {state.columns.map(({
      index,
      ref,
      children
    }) => <div key={index} ref={ref} className={classes.column}>
          {children}
        </div>)}
    </Root>;
}
const classes = {
  column: "cmlp2jp"
};
export default React.memo(Columns);
class Cache {
  private cache = new Map<string, Trie<React.Key, number[]>>();
  get(id: string): undefined | Trie<React.Key, number[]> {
    return this.cache.get(id);
  }
  append(id: string, keysToPlace: React.Key[], numColumns: number, key: React.Key, column: number): void {
    let trie = this.cache.get(id);
    if (!trie) {
      trie = new Trie<React.Key, number[]>();
      this.cache.set(id, trie);
    }
    insertWith(trie, keysToPlace, key, [NaN], node => {
      node.value[numColumns] = column;
    });
  }
}
const cache = new Cache();

require("./Columns.linaria.module.css");