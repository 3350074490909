import { useComputedStyle } from "@/hooks/useComputedStyle";
import { cx } from "@linaria/core";
import * as React from "react";
import { Columns } from "./internal";
import * as F from "fp-ts";

/**
 * The underlying DOM element which is rendered by this component.
 */
const Root = "div";
interface Props extends React.ComponentPropsWithoutRef<typeof Root> {
  id: string;
}
function Masonry(props: Props) {
  const {
    className,
    children,
    ...rest
  } = props;

  /*
   * We watch the computed value of the 'grid-template-columns' property to
   * determine how many columns the element is displaying.
   */
  const [ref, computedStyle] = useComputedStyle(["gridTemplateColumns"]);
  const numColumns = computedStyle?.gridTemplateColumns.split(" ").length ?? 1;

  /*
   * An array of refs which are passed to each column. This array always has
   * length same as 'numColumns'.
   *
   * The refs are used to calculate the height of each column, to find which one
   * is the shortest and where the next item should be placed in.
   */
  const refs = React.useMemo<F.nonEmptyArray.NonEmptyArray<React.MutableRefObject<null | HTMLDivElement>>>(() => F.nonEmptyArray.makeBy(() => ({
    current: null
  }))(numColumns), [numColumns]);

  /*
   * The actual child elements that we should be showing. The array identity
   * only changes when the children change.
   */
  const childElements = React.useMemo(() => React.Children.toArray(children).filter(isValidChild), [children]);
  return <Root ref={ref} className={cx(className, classes.root)} {...rest}>
      {computedStyle && <Columns key={numColumns} refs={refs} childElements={childElements} />}
    </Root>;
}
const classes = {
  root: "r1hcifqy"
};
export default Masonry;
function isValidChild(u: unknown): u is React.ReactElement & {
  key: React.Key;
} {
  return React.isValidElement(u) && u.key !== null;
}

require("./Masonry.linaria.module.css");